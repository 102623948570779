import { UUID } from '@/domains/common';
import * as LxpAssignmentService from '@/services/api/lxp-assignment';
import { useDi } from '@/plugins';
import { useI18n } from '@/plugins/vue-i18n';

type ICatalogueAssignmentParams = { isRecommendations: boolean };

export default function useCatalogueAssignment(params: ICatalogueAssignmentParams) {
  const di = useDi();
  const { t } = useI18n();

  const assignAtom = async (atomId: UUID) => {
    try {
      await LxpAssignmentService.assign({
        paths: {
          catalogueAtomId: atomId,
        },
        data: {
          onRecommendation: params.isRecommendations,
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  };
  const onAssignAtom = async ({ id, resolvePromise }: { id: UUID, resolvePromise: Function }) => {
    const isSuccess = await assignAtom(id);
    if (!isSuccess) {
      di.notify.error({
        title: t('LearningCatalogue.assignErrorMessage'),
      });
      return false;
    }
    resolvePromise();
    di.notify.success({
      title: t('LearningCatalogue.assignSuccessMessage'),
    });
    return true;
  };

  return {
    onAssignAtom,
  };
}
