
import {
  computed, defineComponent, onMounted, reactive, watch,
} from 'vue';
import { debounce } from 'lodash';
import CatalogueExtendedAtomCard from '@/components/catalogue/CatalogueAtomCard/CatalogueExtendedAtomCard.vue';
import { useI18n } from '@/plugins/vue-i18n';
import useCatalogueAssignment from '@/components/catalogue/composables/useCatalogueAssignment';
import useCatalogueAtomCard from '@/components/catalogue/composables/useCatalogueAtomCard';
import { IBreadcrumb } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { AtomSort, IAtom, ICatalogueAtomFilters } from '@/domains/atom';
import { useCatalogueAtomsPageable } from '@/domains/atom/composables';
import * as CatalogueService from '@/services/api/catalogue';
import EmptyAtomsFilteredList from '@/components/catalogue/EmptyAtomsFilteredList.vue';
import UpButton from '@/components/ui/UpButton.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import InfinityScroll from '@/components/ui/InfinityScroll.vue';

export default defineComponent({
  name: 'LearningCatalogueRecommendations',
  components: {
    UpButton,
    TTBackButton,
    InfinityScroll,
    EmptyAtomsFilteredList,
    CatalogueExtendedAtomCard,
  },
  setup() {
    const { t } = useI18n();
    const { onAssignAtom } = useCatalogueAssignment({ isRecommendations: true });
    const { getAtomPreviewUrl } = useCatalogueAtomCard();
    const title = t('LearningCatalogue.breadcrumbs.recommendations');
    const breadcrumbs = computed<IBreadcrumb[]>(() => {
      return [
        {
          text: t('LearningCatalogue.breadcrumbs.myLearning'),
          to: {
            name: Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-learning',
        },
        {
          text: t('LearningCatalogue.breadcrumbs.catalogue'),
          to: {
            name: Names.R_APP_LEARNING_CATALOGUE,
          },
          'data-test-label': 'catalogue',
        },
        {
          text: title,
          disabled: true,
          'data-test-label': 'recommendations',
        },
      ];
    });
    const filters = reactive<ICatalogueAtomFilters>({
      search: '',
      providers: [],
      categories: [],
      durations: [],
      sort: AtomSort.UPDATED_AT_DESC,
      atomNames: [],
    });
    const page = useCatalogueAtomsPageable<IAtom>({
      limit: 20,
      fetchAtomsMethod: CatalogueService.recommendedTracksGet,
    });

    const onFiltersChanged = debounce(async () => {
      page.resetData();
      await page.fetchAtoms(filters);
    }, 300);

    watch(filters, () => {
      onFiltersChanged();
    });

    onMounted(async () => {
      await page.fetchAtoms(filters);
    });

    return {
      title,
      filters,
      breadcrumbs,
      items: page.items,
      limit: page.limit,
      total: page.total,
      isEmpty: page.isEmpty,
      isLoading: page.isLoading,
      currentPage: page.currentPage,
      onAssignAtom,
      getAtomPreviewUrl,
      onAtomsNextPage() {
        page.fetchNextPage(filters);
      },
    };
  },
});
