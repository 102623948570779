import { useRouter } from 'vue-router/composables';
import { Names } from '@/plugins/vue-router';

export default function useCatalogueAtomCard() {
  const router = useRouter();

  const getAtomPreviewUrl = (atomId: UUID, assignmentUserId?: number) => {
    const { location } = router.resolve({
      name: Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
      params: {
        catalogueAtomId: atomId,
      },
      query: {
        assignmentUserId: assignmentUserId ? String(assignmentUserId) : undefined,
      },
    });
    return location;
  };

  return { getAtomPreviewUrl };
}
