
import { computed, defineComponent, PropType } from 'vue';
import { RawLocation } from 'vue-router';
import { useI18n } from '@/plugins/vue-i18n';

import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import { UUID } from '@/domains/common';
import { IContentProvider } from '@/domains/content-provider';
import { ButtonState } from '@/components/tracks/PlusButton/types';
import { AtomType } from '@/domains/atom';

import ProvidersChip from '@/components/ui/ProvidersChip';
import PlusButton from '@/components/tracks/PlusButton';
import AtomRecommendationChip from '@/components/catalogue/CatalogueAtomCard/AtomRecommendationChip.vue';
import AtomCover from '@/components/ui/AtomCover/AtomCover.vue';

export default defineComponent({
  name: 'CatalogueExtendedAtomCard',

  components: {
    ProvidersChip,
    PlusButton,
    AtomRecommendationChip,
    AtomCover,
  },

  inject: ['Names'],

  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      required: true,
    },
    atomId: {
      type: String as PropType<UUID>,
      required: true,
    },
    imageId: {
      type: String as PropType<UUID>,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 0,
    },
    contentProviders: {
      type: Array as PropType<IContentProvider[]>,
      default: () => [],
    },
    isAssigned: {
      type: Boolean,
      default: false,
    },
    isRecommended: {
      type: Boolean,
      default: false,
    },
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    withAtomType: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const assignButtonInitialState = computed(() => (props.isAssigned ? ButtonState.CHECKED : ButtonState.DEFAULT));
    const onAssignButtonClickedHandler = (resolvePromise: Function) => {
      emit('click:assign', { id: props.atomId, resolvePromise });
    };

    const coverImageSrc = computed(() => getImageUrl({
      uuid: props.imageId,
      type: 'course',
      name: 'cover',
      size: '320x320',
    }));

    const durationHours = computed(() => {
      if (props.duration > 60) {
        return Math.trunc(props.duration / 60);
      }
      return 0;
    });

    const durationRemainingMinutesString = computed(() => {
      const remainingMinutes = props.duration - 60 * durationHours.value;
      if (remainingMinutes > 0) {
        return t('CatalogueLearningProgram.humanizedDuration.minutes', { time: remainingMinutes });
      }
      return '';
    });

    const durationString = computed(() => {
      if (durationHours.value) {
        return `${t('CatalogueLearningProgram.humanizedDuration.hours',
          { time: durationHours.value })} ${durationRemainingMinutesString.value}`;
      }

      return t('CatalogueLearningProgram.humanizedDuration.minutes', { time: props.duration });
    });

    return {
      assignButtonInitialState,
      onAssignButtonClickedHandler,
      coverImageSrc,
      durationString,
    };
  },

});
